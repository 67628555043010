<template>
  <v-treeview ref="treeview" expand-icon="" :items="items" :open-all="openAll" open-on-click activatable transition>
    <template #label="{ item }">
      <projects-item
        :type="item.type"
        :name="item.name"
        :more-info-link="getLink(item)"
        :is-archived="item.isArchived"
        :is-loading="item.isLoading"
        :is-open="checkItemIsOpen(item.id)"
        :access-to-rooms="accessToRooms"
        :has-children="!!item.children"
      />
    </template>
  </v-treeview>
</template>

<script>
// Constants
import { PROJECT, BUILDING, UNIT, ROOM, NO_UNITS, NO_ROOMS, NO_BUILDINGS } from '@/constants/projectEntity';
import { BUILDINGS_DETAILED, PROJECTS_DETAILED, UNITS_DETAILED, ROOMS_DETAILED } from '@/constants/routes';

// Components
import ProjectsItem from '@/components/Projects/Item.vue';

export default {
  name: 'ProjectsList',

  components: { ProjectsItem },

  props: {
    projects: { type: Array, required: true },
    openAll: { type: Boolean, default: false },
    accessToRooms: { type: Boolean, required: true },
  },

  data() {
    return {
      open: [],
      projectBuildings: {},
      buildingUnits: {},
      unitRooms: {},
    };
  },

  computed: {
    items() {
      if (this.openAll) {
        return this.projects.map(project => {
          if (!project.buildings.length) return project;

          return {
            ...project,
            children: project.buildings.map(building => {
              if (!building.units.length) {
                return {
                  ...building,
                  type: BUILDING,
                  originalId: building.id,
                  id: `${project.id} ${building.id}`,
                };
              }

              return {
                ...building,
                type: BUILDING,
                originalId: building.id,
                id: `${project.id} ${building.id}`,
                children: building.units.map(unit => {
                  if (!unit.rooms.length) {
                    return {
                      ...unit,
                      type: UNIT,
                      originalId: unit.id,
                      id: `${project.id} ${building.id} ${unit.id}`,
                    };
                  }

                  return {
                    ...unit,
                    type: UNIT,
                    originalId: unit.id,
                    id: `${project.id} ${building.id} ${unit.id}`,
                    children: unit.rooms.map(room => {
                      return {
                        ...room,
                        type: ROOM,
                        originalId: room.id,
                        id: `${project.id} ${building.id} ${unit.id} ${room.id}`,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        });
      }

      return this.projects.map(project => ({
        ...project,
        children: this.getProjectChildren(project),
      }));
    },
  },

  methods: {
    getProjectChildren(project) {
      if (project.buildings.length) {
        return (
          project.buildings.map(building => ({
            ...building,
            type: BUILDING,
            originalId: building.id,
            id: `${project.id} ${building.id}`,
            children: this.getBuildingChildren(building),
          })) || []
        );
      }
      return [this.getEmptyBuilding()];
    },

    getBuildingChildren(building) {
      if (building.units.length) {
        return this.accessToRooms
          ? building.units.map(unit => ({
              ...unit,
              type: UNIT,
              originalId: unit.id,
              id: `${building.id} ${unit.id}`,
              children: this.getUnitChildren(unit),
            })) || [this.getEmptyUnit()]
          : building.units.map(unit => ({
              ...unit,
              type: UNIT,
              originalId: unit.id,
              id: `${building.id} ${unit.id}`,
            })) || [];
      }
      return [this.getEmptyUnit()];
    },

    getUnitChildren(unit) {
      if (unit.rooms.length) {
        return (
          unit.rooms.map(room => ({
            ...room,
            type: ROOM,
            originalId: room.id,
            id: `${unit.id} ${room.id}`,
          })) || []
        );
      }
      return [this.getEmptyRoom()];
    },

    getEmptyBuilding(id) {
      return {
        name: this.$t('projects.no_active_buildings'),
        type: NO_BUILDINGS,
        id: `${id} -1`,
      };
    },

    getEmptyUnit(id) {
      return { name: this.$t('projects.no_active_units'), type: NO_UNITS, id: `${id} -1` };
    },

    getEmptyRoom(id) {
      return { name: this.$t('projects.no_active_rooms'), type: NO_ROOMS, id: `${id} -1` };
    },

    getLink(item) {
      let routeName;

      switch (item.type) {
        case PROJECT:
          routeName = PROJECTS_DETAILED;
          break;
        case BUILDING:
          routeName = BUILDINGS_DETAILED;
          break;
        case UNIT:
          routeName = UNITS_DETAILED;
          break;
        case ROOM:
          routeName = ROOMS_DETAILED;
          break;
        default:
          routeName = '';
          break;
      }
      return { name: routeName, params: { id: item.originalId || item.id }, query: { prevPage: this.page } };
    },

    checkItemIsOpen(itemId) {
      return !!this.open.find(openItemId => openItemId === itemId);
    },
  },
};
</script>
