<template>
  <base-missing-data class="projects-missing-data" :icon="$options.IconMissingDataProjects" :message="message" />
</template>

<script>
import BaseMissingData from '@/components/BaseMissingData.vue';
import IconMissingDataProjects from '@/components/MissingDataIcons/MissingDataProjects.vue';

export default {
  name: 'ProjectsMissingData',

  components: { BaseMissingData },

  props: {
    isArchive: { type: Boolean, default: false },
  },

  computed: {
    message() {
      return this.isArchive ? this.$t('projects.archived_missing_message') : this.$t('projects.missing_message');
    },
  },

  IconMissingDataProjects,
};
</script>
