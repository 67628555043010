<template>
  <div
    class="d-flex align-center text-subtitle-1 text-md-h6"
    :class="{
      projects__project: isProject,
      projects__building: isBuilding,
      projects__unit: isUnit,
      projects__room: isRoom,
      'projects__no-buildings': isNoBuildings,
      'projects__no-units': isNoUnits,
      'projects__no-rooms': isNoRooms,
      pointer: hasChevron,
    }"
  >
    <router-link
      v-if="!isEmpty"
      class="projects__item-name"
      :to="moreInfoLink"
      :class="{ 'projects__item-name--archive': isArchived }"
      @click.stop
    >
      {{ name }}
    </router-link>

    <span
      v-else
      class="projects__item-name"
      :class="{
        'projects__empty-text': isEmpty,
        'projects__item-name--archive': isArchived,
      }"
    >
      {{ name }}
    </span>

    <template v-if="!isEmpty">
      <v-spacer />

      <v-btn
        class="projects__more-info mr-0 mr-md-2 text-caption text-md-body-2 text-uppercase"
        :to="moreInfoLink"
        text
        small
        @click.stop
      >
        {{ $t('projects.more_info') }}
      </v-btn>

      <div>
        <v-progress-circular v-if="isLoading" color="primary" width="2" size="24" indeterminate />

        <v-icon v-else-if="hasChevron" size="24">
          <template v-if="isOpen">
            mdi-chevron-up
          </template>
          <template v-else>
            mdi-chevron-down
          </template>
        </v-icon>

        <div v-else class="projects__icon-stub"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { PROJECT, BUILDING, UNIT, ROOM, NO_BUILDINGS, NO_UNITS, NO_ROOMS } from '@/constants/projectEntity';

export default {
  name: 'ProjectsItem',

  props: {
    type: { type: String, required: true },
    name: { type: String, required: true },
    moreInfoLink: { type: Object, required: true },
    isArchived: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    accessToRooms: { type: Boolean, required: true },
    hasChildren: { type: Boolean, default: true },
  },

  computed: {
    isProject() {
      return this.type === PROJECT;
    },

    isBuilding() {
      return this.type === BUILDING;
    },

    isUnit() {
      return this.type === UNIT;
    },

    isRoom() {
      return this.type === ROOM;
    },

    isNoBuildings() {
      return this.type === NO_BUILDINGS;
    },

    isNoUnits() {
      return this.type === NO_UNITS;
    },

    isNoRooms() {
      return this.type === NO_ROOMS;
    },

    isEmpty() {
      return this.isNoBuildings || this.isNoUnits || this.isNoRooms;
    },

    hasChevron() {
      if (!this.hasChildren) return false;
      return this.accessToRooms ? !this.isRoom : !this.isUnit;
    },
  },
};
</script>
