var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center text-subtitle-1 text-md-h6",class:{
    projects__project: _vm.isProject,
    projects__building: _vm.isBuilding,
    projects__unit: _vm.isUnit,
    projects__room: _vm.isRoom,
    'projects__no-buildings': _vm.isNoBuildings,
    'projects__no-units': _vm.isNoUnits,
    'projects__no-rooms': _vm.isNoRooms,
    pointer: _vm.hasChevron,
  }},[(!_vm.isEmpty)?_c('router-link',{staticClass:"projects__item-name",class:{ 'projects__item-name--archive': _vm.isArchived },attrs:{"to":_vm.moreInfoLink},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_c('span',{staticClass:"projects__item-name",class:{
      'projects__empty-text': _vm.isEmpty,
      'projects__item-name--archive': _vm.isArchived,
    }},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(!_vm.isEmpty)?[_c('v-spacer'),_c('v-btn',{staticClass:"projects__more-info mr-0 mr-md-2 text-caption text-md-body-2 text-uppercase",attrs:{"to":_vm.moreInfoLink,"text":"","small":""},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.$t('projects.more_info'))+" ")]),_c('div',[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"color":"primary","width":"2","size":"24","indeterminate":""}}):(_vm.hasChevron)?_c('v-icon',{attrs:{"size":"24"}},[(_vm.isOpen)?[_vm._v(" mdi-chevron-up ")]:[_vm._v(" mdi-chevron-down ")]],2):_c('div',{staticClass:"projects__icon-stub"})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }